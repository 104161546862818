import { useQuery } from "@tanstack/react-query";
import { List } from "antd";
import * as currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clientService from "../../../../services/http-client";
import { Course10, Icon1, Icon2, User1 } from "../../../imagepath";

const GridInnerPage = (props) => {
  const { data, isLoading } = useQuery({
    queryKey: ["khoaHocGetPublic"],
    queryFn: () => clientService.api.khoaHocGetPublic(),
  });
  const [khoaHoc, setKhoaHoc] = useState([]);
  useEffect(() => {
    if (data) {
      setKhoaHoc(
        data.filter((item) => {
          return (
            props.filter.includes(item.danhMucDaoTao.id) &&
            item.tenKhoaHoc
              .toLowerCase()
              .includes(props.search?.toLowerCase() ?? "")
          );
        })
      );
    }
  }, [data, props.filter, props.search]);

  return (
    <>
      <List
        dataSource={khoaHoc}
        pagination={{
          pageSize: 9,
          align: "center",
          total: khoaHoc?.length,
        }}
        className="khoahoc-list"
        renderItem={(item, index) => {
          const tutor = item?.coCauNhanSu?.find((i) => i.chucVu?.id === 2);
          return (
            <div key={index} className="col-lg-6 col-md-6 d-flex">
              <div className="course-box course-design d-flex ">
                <div className="product">
                  <div className="product-img">
                    <Link to={`/course-details/${item.id}`}>
                      <img
                        className="img-fluid"
                        alt=""
                        src={item?.hinhAnh?.path ?? Course10}
                      />
                    </Link>
                    <div className="price">
                      <h3>
                        {currencyFormatter.format(item.giaTien ?? 0, {
                          code: "VND",
                          symbol: "đ",
                        })}
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <img
                          src={tutor?.user?.avatar?.path ?? User1}
                          alt=""
                          className="img-fluid"
                        />
                        <div className="course-name">
                          <p>Giảng viên</p>
                          <h4>{tutor?.user?.fullName}</h4>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <Link to="#">
                          <i className="fa-regular fa-heart" />
                        </Link>
                      </div>
                    </div>
                    <h3 className="title">
                      <Link to={`/course-details/${item.id}`}>
                        {item.tenKhoaHoc}
                      </Link>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img src={Icon1} alt="" />
                        <p>Số tiết: {item.soTiet}</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img src={Icon2} alt="" />
                        <p>
                          Khai giảng:{" "}
                          {item?.ngayKhaiGiang
                            ? dayjs(item?.ngayKhaiGiang).format("DD/MM/YYYY")
                            : "Chưa có"}
                        </p>
                      </div>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      {item?.ngayKetThuc &&
                      dayjs(item?.ngayKetThuc).isBefore(dayjs()) ? (
                        <span className="btn btn-primary disabled" style={{color:"red", borderColor:"red"}}>
                          Khóa học đã kết thúc
                        </span>
                      ) : (
                        <Link
                          to={`/course-enroll/${item.id}`}
                          className="btn btn-primary"
                          disable
                        >
                          {item?.ngayKhaiGiang ? "Ghi danh" : "Đăng ký giữ chỗ"}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default GridInnerPage;
